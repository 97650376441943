<template>
    <div v-if="modelValue">
        <div :class="classNames.modal" tabIndex="-1" role="dialog" :aria-label="aria.openMessage" @click="$emit('update:modelValue', false)" @keydown.esc="$emit('update:modelValue', false)">
            <div :class="[classNames.modalBody, {'modal-img-body': isImg }]">
                <div :class="classNames.modalInner">
                    <div :class="classNames.modalIframeWrap" :style="{'padding-bottom': paddingBottom}">
                        <button :class="classNames.modalCloseBtn" :aria-label="aria.dismissBtnMessage" ref="closeBtn" @click="$emit('update:modelValue', false)"></button>

                        <template v-if="!isImg">
                            <iframe width="460"
                                    height="230"
                                    :src="fullVideoUrl"
                                    frameBorder="0"
                                    :allowFullScreen="allowFullScreen"
                                    tabIndex="-1">
                            </iframe>
                        </template>
                        <template v-else>
                            <NuxtImg :src="imgSrc" class="wide" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    const closeBtn = ref<Nullable<HTMLElement>>(null);

    const body = ref<Nullable<HTMLElement>>(document?.body);

    const modelValue = defineModel({ type: Boolean });

    const props = defineProps({
        imgSrc: String,
        videoId: String,
        youtube: {
            type: Object,
            default: function () {
                return {
                    autoplay: 0,
                    cc_load_policy: 1,
                    color: null,
                    controls: 1,
                    disablekb: 0,
                    enablejsapi: 0,
                    end: null,
                    fs: 1,
                    h1: null,
                    iv_load_policy: 1,
                    list: null,
                    listType: null,
                    loop: 0,
                    modestbranding: null,
                    origin: null,
                    playlist: null,
                    playsinline: null,
                    rel: 0,
                    showinfo: 1,
                    start: 0,
                    wmode: 'transparent',
                    theme: 'dark'
                }
            }
        },
        ratio: {
            type: String,
            default: '16:9'
        },
        allowFullScreen: {
            type: Boolean,
            default: true
        },
        animationSpeed: {
            type: Number,
            default: 300
        },
        classNames: {
            type: Object,
            default: function () {
                return {
                    modalEffect: 'media-modal-effect',
                    modal: 'media-modal',
                    modalBody: 'media-modal-body',
                    modalInner: 'media-modal-inner',
                    modalIframeWrap: 'media-modal-wrap',
                    modalCloseBtn: 'media-modal-close-btn'
                }
            }
        },
        aria: {
            type: Object,
            default: function () {
                return {
                    openMessage: 'Modal opened',
                    dismissBtnMessage: 'Close the modal'
                }
            }
        },
        bodyClassName: {
            type: String,
            default: 'overflow-hidden'
        }
    });

    function getQueryString(obj: any): string {
        if (!obj || typeof obj !== 'object')
            return '';      

        return Object.keys(obj)
            .filter(key => obj[key] != null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
    }

    function getYoutubeUrl(youtube: Record<string, any>, videoId: string): string {
        const query = getQueryString(youtube);
        return `//www.youtube.com/embed/${videoId}?${query}`;
    }

    function getPadding(ratio: string): string {
        const [width, height] = ratio.split(':').map(Number);
        const padding = (height / width) * 100;
        return `${padding}%`;
    }

    const fullVideoUrl = computed(() => getYoutubeUrl(props.youtube, props.videoId!));

    const paddingBottom = computed(() => getPadding(props.ratio));

    const isImg = computed(() => props.imgSrc && !props.videoId);

    watch(modelValue, (newVal) => {
        if (newVal)
            body?.value!.classList.add(props.bodyClassName);
        else
            body?.value!.classList.remove(props.bodyClassName);
    });
</script>

<style lang="scss" scoped>
    @keyframes media-modal {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes media-modal-inner {
        from {
            transform: translate(0, 100px);
        }

        to {
            transform: translate(0, 0);
        }
    }

    .media-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 1000000;
        cursor: pointer;
        opacity: 1;
        animation-timing-function: ease-out;
        animation-duration: 0.3s;
        animation-name: media-modal;
        transition: opacity 0.3s ease-out;

        .media-modal-body {
            max-width: 1100px;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            display: table;
            position: relative;

            &.modal-img-body {
                max-width: 500px;
            }

            .media-modal-inner {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                height: 100%;
                position: relative;

                .media-modal-wrap {
                    width: 100%;
                    height: 0;
                    position: relative;
                    padding-bottom: 56.25%;
                    background-color: #333;
                    animation-timing-function: ease-out;
                    animation-duration: 0.3s;
                    animation-name: media-modal-inner;
                    transform: translate(0, 0);
                    transition: transform 0.3s ease-out;

                    iframe {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    .media-modal-close-btn {
                        position: absolute;
                        z-index: 2;
                        top: -25px;
                        right: 0;
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        overflow: hidden;
                        border: none;
                        background: transparent;
                        cursor: pointer;

                        &:before, &:after {
                            content: '';
                            position: absolute;
                            height: 2px;
                            width: 100%;
                            top: 50%;
                            left: 0;
                            background: #fff;
                            border-radius: 5px;
                        }

                        &:before {
                            transform: rotate(45deg) translateY(-50%);
                        }

                        &:after {
                            transform: rotate(-45deg) translateY(-50%);
                        }
                    }
                }
            }
        }
    }
</style>